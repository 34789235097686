import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import Subscribe from '../components/subscribe/subscribe';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import NewsletterFeedback from '../components/NewsletterFeedback/NewsletterFeedback';
// import { Helmet } from 'react-helmet'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost

    return (
        <ThemeProvider theme={theme}>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            {/* <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
                <title>{post.title}</title>
                <meta name="description" content={post.description}></meta>
            </Helmet> */}
            <Layout>
                <div className="container">
                    <article className="content">
                        <section className="post-full-content">
                            <h1 className="content-title" style={{ textAlign: "center" }}>{post.title}</h1>
                            {post.feature_image ?
                                <figure className="post-feature-image">
                                    <img src={post.feature_image} alt={post.title} />
                                </figure> : null}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </div>
  
                <div className={"container"}>
                    <div className={"page-section home"}>
                        <h1 style={{ color: "#3d84a8" }}>Subscribe to Managers Weekly Newsletter</h1>
                        <p style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            Here is What Our Readers Say
                        </p>
                    </div>
                    <NewsletterFeedback />
                    <div className={"features"}>
                        <div className={"feature__item"}>
                        <div className={"row"}>
                            <div className={"col-12 first"}>
                                <Subscribe 
                                    title=""
                                    buttonText="Subscribe" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {/* <div className={"container"}>
                    <div className={"features"}>
                        <div className={"feature__item"}>
                            <div className={"row"}>
                                <div className={"col-12 first"}>
                                    <Subscribe
                                        title="Get our newsletters and blog posts delivered right to your inbox"
                                        buttonText="Subscribe"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </Layout>
        </ThemeProvider>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
